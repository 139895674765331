<template>
  <div>
    <Modal class="pop-inquiry" footer-hide v-model="showInquiry">
      <div class="top">
        <div class="product">
          {{ inquiry.product_name }} <span>[{{ inquiry.type_desc }}]</span>
        </div>
        <div class="status status-progress" v-if="inquiry.status == 1">
          Active
        </div>
        <div class="status status-completed" v-if="inquiry.status == 2">
          Completed
        </div>
        <div class="status status-expired" v-if="inquiry.status == 3">
          Expired
        </div>
      </div>
      <div class="content">
        <div class="line-item">
          <div class="item">
            <div class="label">
              Quantity
              <span
                class="edit-value"
                @click="editValue('quantity')"
                v-if="!this.showInfo"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.4568 9.01329L30.9868 12.5416M29.7268 5.90496L20.1818 15.45C19.6886 15.9425 19.3523 16.5699 19.2152 17.2533L18.3335 21.6666L22.7468 20.7833C23.4302 20.6466 24.0568 20.3116 24.5502 19.8183L34.0952 10.2733C34.382 9.98646 34.6095 9.64594 34.7647 9.27118C34.92 8.89642 34.9999 8.49476 34.9999 8.08912C34.9999 7.68348 34.92 7.28182 34.7647 6.90706C34.6095 6.5323 34.382 6.19178 34.0952 5.90496C33.8083 5.61813 33.4678 5.3906 33.0931 5.23537C32.7183 5.08014 32.3166 5.00024 31.911 5.00024C31.5054 5.00024 31.1037 5.08014 30.7289 5.23537C30.3542 5.3906 30.0137 5.61813 29.7268 5.90496V5.90496Z"
                    stroke="#F57F45"
                    stroke-width="3.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M31.6667 24.9999V29.9999C31.6667 30.884 31.3156 31.7318 30.6904 32.3569C30.0653 32.9821 29.2175 33.3333 28.3334 33.3333H10.0001C9.11603 33.3333 8.26818 32.9821 7.64306 32.3569C7.01794 31.7318 6.66675 30.884 6.66675 29.9999V11.6666C6.66675 10.7825 7.01794 9.93468 7.64306 9.30956C8.26818 8.68444 9.11603 8.33325 10.0001 8.33325H15.0001"
                    stroke="#F57F45"
                    stroke-width="3.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div class="value" :title="inquiry.quantity">
              <template v-if="!editValueData.quote_quantity">{{
                inquiry.quantity || "--"
              }}</template>
              <template v-else>
                <input type="text" v-model="editValueObj.quote_quantity" />
              </template>
            </div>
          </div>
          <div class="item">
            <div class="label">
              Specification
              <span
                class="edit-value"
                @click="editValue('quality_standard')"
                v-if="!this.showInfo"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.4568 9.01329L30.9868 12.5416M29.7268 5.90496L20.1818 15.45C19.6886 15.9425 19.3523 16.5699 19.2152 17.2533L18.3335 21.6666L22.7468 20.7833C23.4302 20.6466 24.0568 20.3116 24.5502 19.8183L34.0952 10.2733C34.382 9.98646 34.6095 9.64594 34.7647 9.27118C34.92 8.89642 34.9999 8.49476 34.9999 8.08912C34.9999 7.68348 34.92 7.28182 34.7647 6.90706C34.6095 6.5323 34.382 6.19178 34.0952 5.90496C33.8083 5.61813 33.4678 5.3906 33.0931 5.23537C32.7183 5.08014 32.3166 5.00024 31.911 5.00024C31.5054 5.00024 31.1037 5.08014 30.7289 5.23537C30.3542 5.3906 30.0137 5.61813 29.7268 5.90496V5.90496Z"
                    stroke="#F57F45"
                    stroke-width="3.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M31.6667 24.9999V29.9999C31.6667 30.884 31.3156 31.7318 30.6904 32.3569C30.0653 32.9821 29.2175 33.3333 28.3334 33.3333H10.0001C9.11603 33.3333 8.26818 32.9821 7.64306 32.3569C7.01794 31.7318 6.66675 30.884 6.66675 29.9999V11.6666C6.66675 10.7825 7.01794 9.93468 7.64306 9.30956C8.26818 8.68444 9.11603 8.33325 10.0001 8.33325H15.0001"
                    stroke="#F57F45"
                    stroke-width="3.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div class="value" :title="inquiry.quality_standard">
              <template v-if="!editValueData.quote_quality_standard">{{
                inquiry.quality_standard || "--"
              }}</template>
              <template v-else>
                <input
                  type="text"
                  v-model="editValueObj.quote_quality_standard"
                />
              </template>
            </div>
          </div>

          <div class="item">
            <div class="label">
              Packaging
              <span
                class="edit-value"
                @click="editValue('packaging')"
                v-if="!this.showInfo"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.4568 9.01329L30.9868 12.5416M29.7268 5.90496L20.1818 15.45C19.6886 15.9425 19.3523 16.5699 19.2152 17.2533L18.3335 21.6666L22.7468 20.7833C23.4302 20.6466 24.0568 20.3116 24.5502 19.8183L34.0952 10.2733C34.382 9.98646 34.6095 9.64594 34.7647 9.27118C34.92 8.89642 34.9999 8.49476 34.9999 8.08912C34.9999 7.68348 34.92 7.28182 34.7647 6.90706C34.6095 6.5323 34.382 6.19178 34.0952 5.90496C33.8083 5.61813 33.4678 5.3906 33.0931 5.23537C32.7183 5.08014 32.3166 5.00024 31.911 5.00024C31.5054 5.00024 31.1037 5.08014 30.7289 5.23537C30.3542 5.3906 30.0137 5.61813 29.7268 5.90496V5.90496Z"
                    stroke="#F57F45"
                    stroke-width="3.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M31.6667 24.9999V29.9999C31.6667 30.884 31.3156 31.7318 30.6904 32.3569C30.0653 32.9821 29.2175 33.3333 28.3334 33.3333H10.0001C9.11603 33.3333 8.26818 32.9821 7.64306 32.3569C7.01794 31.7318 6.66675 30.884 6.66675 29.9999V11.6666C6.66675 10.7825 7.01794 9.93468 7.64306 9.30956C8.26818 8.68444 9.11603 8.33325 10.0001 8.33325H15.0001"
                    stroke="#F57F45"
                    stroke-width="3.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div class="value" :title="inquiry.packaging">
              <template v-if="!editValueData.quote_packaging">{{
                inquiry.packaging || "--"
              }}</template>
              <template v-else>
                <input type="text" v-model="editValueObj.quote_packaging" />
              </template>
            </div>
          </div>
        </div>
        <div class="line-item">
          <div class="item item-c">
            <div class="label">Factory Name</div>
            <div class="value" :title="inquiry.factory">
              {{ inquiry.factory || "--" }}
            </div>
          </div>
          <div class="item">
            <div class="label">
              Lead Time
              <span
                class="edit-value"
                @click="editValue('lead_time')"
                v-if="!this.showInfo"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.4568 9.01329L30.9868 12.5416M29.7268 5.90496L20.1818 15.45C19.6886 15.9425 19.3523 16.5699 19.2152 17.2533L18.3335 21.6666L22.7468 20.7833C23.4302 20.6466 24.0568 20.3116 24.5502 19.8183L34.0952 10.2733C34.382 9.98646 34.6095 9.64594 34.7647 9.27118C34.92 8.89642 34.9999 8.49476 34.9999 8.08912C34.9999 7.68348 34.92 7.28182 34.7647 6.90706C34.6095 6.5323 34.382 6.19178 34.0952 5.90496C33.8083 5.61813 33.4678 5.3906 33.0931 5.23537C32.7183 5.08014 32.3166 5.00024 31.911 5.00024C31.5054 5.00024 31.1037 5.08014 30.7289 5.23537C30.3542 5.3906 30.0137 5.61813 29.7268 5.90496V5.90496Z"
                    stroke="#F57F45"
                    stroke-width="3.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M31.6667 24.9999V29.9999C31.6667 30.884 31.3156 31.7318 30.6904 32.3569C30.0653 32.9821 29.2175 33.3333 28.3334 33.3333H10.0001C9.11603 33.3333 8.26818 32.9821 7.64306 32.3569C7.01794 31.7318 6.66675 30.884 6.66675 29.9999V11.6666C6.66675 10.7825 7.01794 9.93468 7.64306 9.30956C8.26818 8.68444 9.11603 8.33325 10.0001 8.33325H15.0001"
                    stroke="#F57F45"
                    stroke-width="3.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div class="value" :title="inquiry.lead_time">
              <template v-if="!editValueData.quote_lead_time">{{
                inquiry.lead_time || "--"
              }}</template>
              <template v-else>
                <input type="text" v-model="editValueObj.quote_lead_time" />
              </template>
            </div>
          </div>
        </div>
        <div class="line-item">
          <div class="item item-c">
            <div class="label" title="QC Documents/Factory Certificate">
              QC Documents/Factory Certificate
            </div>
            <div class="value search-button">
              <template v-if="inquiry.qc.length > 0">
                <a :href="inquiry.qc[0].url" target="_blank">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.68 13.89C23.1338 9.5875 19.45 6.25 15 6.25C11.555 6.25 8.5625 8.2625 7.19625 11.4363C4.51125 12.24 2.5 14.775 2.5 17.5C2.5 20.8213 5.11125 23.5188 8.385 23.7138V23.75H22.4875V23.7462L22.5 23.75C25.2575 23.75 27.5 21.5075 27.5 18.75C27.4985 17.6294 27.1214 16.5416 26.4289 15.6606C25.7364 14.7796 24.7685 14.1562 23.68 13.89V13.89ZM10 15H13.75V11.25H16.25V15H20L15 21.25L10 15Z"
                      fill="#F57F45"
                    />
                  </svg>
                </a>
              </template>
              <template v-else>--</template>
            </div>
          </div>
          <div class="item">
            <div class="label">COA / Specification</div>
            <div class="value search-button">
              <template v-if="inquiry.coa.length > 0">
                <a :href="inquiry.coa[0].url" target="_blank">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.68 13.89C23.1338 9.5875 19.45 6.25 15 6.25C11.555 6.25 8.5625 8.2625 7.19625 11.4363C4.51125 12.24 2.5 14.775 2.5 17.5C2.5 20.8213 5.11125 23.5188 8.385 23.7138V23.75H22.4875V23.7462L22.5 23.75C25.2575 23.75 27.5 21.5075 27.5 18.75C27.4985 17.6294 27.1214 16.5416 26.4289 15.6606C25.7364 14.7796 24.7685 14.1562 23.68 13.89V13.89ZM10 15H13.75V11.25H16.25V15H20L15 21.25L10 15Z"
                      fill="#F57F45"
                    />
                  </svg>
                </a>
              </template>
              <template v-else>--</template>
            </div>
          </div>
        </div>
        <div class="line-item">
          <div class="item">
            <div class="label">Shelf Life Expiration Date</div>
            <div class="value">
              {{ inquiry.product_expire_date | sliceDate }}
            </div>
          </div>
          <div class="item">
            <div class="label">
              Basis(FOB/CIF/CFR/DDP)
              <span
                class="edit-value"
                @click="editValue('basis')"
                v-if="!this.showInfo"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.4568 9.01329L30.9868 12.5416M29.7268 5.90496L20.1818 15.45C19.6886 15.9425 19.3523 16.5699 19.2152 17.2533L18.3335 21.6666L22.7468 20.7833C23.4302 20.6466 24.0568 20.3116 24.5502 19.8183L34.0952 10.2733C34.382 9.98646 34.6095 9.64594 34.7647 9.27118C34.92 8.89642 34.9999 8.49476 34.9999 8.08912C34.9999 7.68348 34.92 7.28182 34.7647 6.90706C34.6095 6.5323 34.382 6.19178 34.0952 5.90496C33.8083 5.61813 33.4678 5.3906 33.0931 5.23537C32.7183 5.08014 32.3166 5.00024 31.911 5.00024C31.5054 5.00024 31.1037 5.08014 30.7289 5.23537C30.3542 5.3906 30.0137 5.61813 29.7268 5.90496V5.90496Z"
                    stroke="#F57F45"
                    stroke-width="3.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M31.6667 24.9999V29.9999C31.6667 30.884 31.3156 31.7318 30.6904 32.3569C30.0653 32.9821 29.2175 33.3333 28.3334 33.3333H10.0001C9.11603 33.3333 8.26818 32.9821 7.64306 32.3569C7.01794 31.7318 6.66675 30.884 6.66675 29.9999V11.6666C6.66675 10.7825 7.01794 9.93468 7.64306 9.30956C8.26818 8.68444 9.11603 8.33325 10.0001 8.33325H15.0001"
                    stroke="#F57F45"
                    stroke-width="3.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div class="value" :title="inquiry.basis">
              <template v-if="!editValueData.quote_basis">{{
                inquiry.basis || "--"
              }}</template>
              <template v-else>
                <input type="text" v-model="editValueObj.quote_basis" />
              </template>
            </div>
          </div>
          <div class="item">
            <div class="label">
              Instock address
              <span
                class="edit-value"
                @click="editValue('shipping_address')"
                v-if="!this.showInfo"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.4568 9.01329L30.9868 12.5416M29.7268 5.90496L20.1818 15.45C19.6886 15.9425 19.3523 16.5699 19.2152 17.2533L18.3335 21.6666L22.7468 20.7833C23.4302 20.6466 24.0568 20.3116 24.5502 19.8183L34.0952 10.2733C34.382 9.98646 34.6095 9.64594 34.7647 9.27118C34.92 8.89642 34.9999 8.49476 34.9999 8.08912C34.9999 7.68348 34.92 7.28182 34.7647 6.90706C34.6095 6.5323 34.382 6.19178 34.0952 5.90496C33.8083 5.61813 33.4678 5.3906 33.0931 5.23537C32.7183 5.08014 32.3166 5.00024 31.911 5.00024C31.5054 5.00024 31.1037 5.08014 30.7289 5.23537C30.3542 5.3906 30.0137 5.61813 29.7268 5.90496V5.90496Z"
                    stroke="#F57F45"
                    stroke-width="3.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M31.6667 24.9999V29.9999C31.6667 30.884 31.3156 31.7318 30.6904 32.3569C30.0653 32.9821 29.2175 33.3333 28.3334 33.3333H10.0001C9.11603 33.3333 8.26818 32.9821 7.64306 32.3569C7.01794 31.7318 6.66675 30.884 6.66675 29.9999V11.6666C6.66675 10.7825 7.01794 9.93468 7.64306 9.30956C8.26818 8.68444 9.11603 8.33325 10.0001 8.33325H15.0001"
                    stroke="#F57F45"
                    stroke-width="3.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div class="value" :title="inquiry.shipping_address">
              <template v-if="!editValueData.quote_shipping_address">{{
                inquiry.shipping_address || "--"
              }}</template>
              <template v-else>
                <input
                  type="text"
                  v-model="editValueObj.quote_shipping_address"
                />
              </template>
            </div>
          </div>
        </div>
        <div class="line-item">
          <div class="item">
            <div class="label">
              Price ($/kg)
              <span
                class="edit-value"
                @click="editValue('price')"
                v-if="!this.showInfo"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.4568 9.01329L30.9868 12.5416M29.7268 5.90496L20.1818 15.45C19.6886 15.9425 19.3523 16.5699 19.2152 17.2533L18.3335 21.6666L22.7468 20.7833C23.4302 20.6466 24.0568 20.3116 24.5502 19.8183L34.0952 10.2733C34.382 9.98646 34.6095 9.64594 34.7647 9.27118C34.92 8.89642 34.9999 8.49476 34.9999 8.08912C34.9999 7.68348 34.92 7.28182 34.7647 6.90706C34.6095 6.5323 34.382 6.19178 34.0952 5.90496C33.8083 5.61813 33.4678 5.3906 33.0931 5.23537C32.7183 5.08014 32.3166 5.00024 31.911 5.00024C31.5054 5.00024 31.1037 5.08014 30.7289 5.23537C30.3542 5.3906 30.0137 5.61813 29.7268 5.90496V5.90496Z"
                    stroke="#F57F45"
                    stroke-width="3.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M31.6667 24.9999V29.9999C31.6667 30.884 31.3156 31.7318 30.6904 32.3569C30.0653 32.9821 29.2175 33.3333 28.3334 33.3333H10.0001C9.11603 33.3333 8.26818 32.9821 7.64306 32.3569C7.01794 31.7318 6.66675 30.884 6.66675 29.9999V11.6666C6.66675 10.7825 7.01794 9.93468 7.64306 9.30956C8.26818 8.68444 9.11603 8.33325 10.0001 8.33325H15.0001"
                    stroke="#F57F45"
                    stroke-width="3.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div class="value" :title="inquiry.price">
              <template v-if="!editValueData.quote_price">{{
                inquiry.price || "--"
              }}</template>
              <template v-else>
                <input type="text" v-model="editValueObj.quote_price" />
              </template>
            </div>
          </div>
          <div class="item">
            <div class="label">Type</div>
            <div class="value" :title="inquiry.type_desc">
              {{ inquiry.type_desc || "--" }}
            </div>
          </div>
          <div class="item">
            <div class="label">
              Destination Country
              <span
                class="edit-value"
                @click="editValue('country')"
                v-if="!this.showInfo"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M27.4568 9.01329L30.9868 12.5416M29.7268 5.90496L20.1818 15.45C19.6886 15.9425 19.3523 16.5699 19.2152 17.2533L18.3335 21.6666L22.7468 20.7833C23.4302 20.6466 24.0568 20.3116 24.5502 19.8183L34.0952 10.2733C34.382 9.98646 34.6095 9.64594 34.7647 9.27118C34.92 8.89642 34.9999 8.49476 34.9999 8.08912C34.9999 7.68348 34.92 7.28182 34.7647 6.90706C34.6095 6.5323 34.382 6.19178 34.0952 5.90496C33.8083 5.61813 33.4678 5.3906 33.0931 5.23537C32.7183 5.08014 32.3166 5.00024 31.911 5.00024C31.5054 5.00024 31.1037 5.08014 30.7289 5.23537C30.3542 5.3906 30.0137 5.61813 29.7268 5.90496V5.90496Z"
                    stroke="#F57F45"
                    stroke-width="3.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M31.6667 24.9999V29.9999C31.6667 30.884 31.3156 31.7318 30.6904 32.3569C30.0653 32.9821 29.2175 33.3333 28.3334 33.3333H10.0001C9.11603 33.3333 8.26818 32.9821 7.64306 32.3569C7.01794 31.7318 6.66675 30.884 6.66675 29.9999V11.6666C6.66675 10.7825 7.01794 9.93468 7.64306 9.30956C8.26818 8.68444 9.11603 8.33325 10.0001 8.33325H15.0001"
                    stroke="#F57F45"
                    stroke-width="3.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div class="value" :title="inquiry.country_desc">
              <template v-if="!editValueData.quote_country">
                {{ inquiry.country_desc || "--" }}
              </template>
              <template v-else>
                <select v-model="editValueObj.quote_country">
                  <option
                    v-for="item in countryList"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}</option
                  >
                </select>
              </template>
            </div>
          </div>
        </div>
        <div class="line-item">
          <div class="item item-all">
            <div class="label">Selling Note</div>
            <div class="value value-c" :title="inquiry.note">
              {{ inquiry.note || "--" }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="note-a"
        v-if="
          inquiry.is_involved !== 1 &&
            !inquiry.is_my_inquiry &&
            inquiry.status === 1
        "
      >
        <div class="note item">
          <div class="input">
            <div class="label">
              Note
            </div>
            <div class="value value-c">
              <input
                type="text"
                v-model="inquiryForm.note"
                placeholder="Leave a message"
              />
            </div>
          </div>
        </div>
        <div class="item annual">
          <div class="input">
            <div class="label">
              Estimated Annual Quantity
            </div>
            <div class="value">
              <input
                type="text"
                v-model="inquiryForm.quote_estimated_annual_quantity"
              />
            </div>
          </div>
          <div class="input">
            <div class="label">
              Type
            </div>
            <div class="value">
              <select v-model="inquiryForm.quote_sourcing_type">
                <option value="">- Select Type -</option>
                <option value="Spot Purchasing">Spot Purchasing</option>
                <option value="Blanket Order">Blanket Order</option>
                <option value="Annual Bid">Annual Bid</option>
                <option value="RFQ">RFQ</option>
                <option value="R&D">R&D</option>
                <option value="Quotation">Quotation</option>
                <option value="Market Research">Market Research</option>
              </select>
            </div>
          </div>
          <div class="input"></div>
        </div>
        <div class="item item-vendor" v-if="!showVendor">
          <div
            class="vendor-notify search-button vendor-notify-extra"
            @click="showVendorMethod()"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 1.25C5.16797 1.25 1.25 5.16797 1.25 10C1.25 14.832 5.16797 18.75 10 18.75C14.832 18.75 18.75 14.832 18.75 10C18.75 5.16797 14.832 1.25 10 1.25ZM10 17.2656C5.98828 17.2656 2.73438 14.0117 2.73438 10C2.73438 5.98828 5.98828 2.73438 10 2.73438C14.0117 2.73438 17.2656 5.98828 17.2656 10C17.2656 14.0117 14.0117 17.2656 10 17.2656Z"
                fill="#F57F45"
              />
              <path
                d="M12.1797 6.18555C11.5937 5.67188 10.8203 5.39062 10 5.39062C9.17969 5.39062 8.40625 5.67383 7.82031 6.18555C7.21094 6.71875 6.875 7.43555 6.875 8.20312V8.35156C6.875 8.4375 6.94531 8.50781 7.03125 8.50781H7.96875C8.05469 8.50781 8.125 8.4375 8.125 8.35156V8.20312C8.125 7.3418 8.9668 6.64062 10 6.64062C11.0332 6.64062 11.875 7.3418 11.875 8.20312C11.875 8.81055 11.4453 9.36719 10.7793 9.62305C10.3652 9.78125 10.0137 10.0586 9.76172 10.4219C9.50586 10.793 9.37305 11.2383 9.37305 11.6895V12.1094C9.37305 12.1953 9.44336 12.2656 9.5293 12.2656H10.4668C10.5527 12.2656 10.623 12.1953 10.623 12.1094V11.666C10.6241 11.4764 10.6822 11.2915 10.7898 11.1354C10.8975 10.9793 11.0497 10.8593 11.2266 10.791C12.3789 10.3477 13.123 9.33203 13.123 8.20312C13.125 7.43555 12.7891 6.71875 12.1797 6.18555ZM9.21875 14.2969C9.21875 14.5041 9.30106 14.7028 9.44757 14.8493C9.59409 14.9958 9.7928 15.0781 10 15.0781C10.2072 15.0781 10.4059 14.9958 10.5524 14.8493C10.6989 14.7028 10.7812 14.5041 10.7812 14.2969C10.7812 14.0897 10.6989 13.891 10.5524 13.7444C10.4059 13.5979 10.2072 13.5156 10 13.5156C9.7928 13.5156 9.59409 13.5979 9.44757 13.7444C9.30106 13.891 9.21875 14.0897 9.21875 14.2969Z"
                fill="#F57F45"
              />
            </svg>

            <span
              >Want to enter customer information? (For Internal using
              only)</span
            >
          </div>
        </div>
        <div class="vendor" v-if="showVendor">
          <div class="title">Customer Information (Internal use only)</div>
          <div class="item extra-item">
            <div class="input">
              <div class="label">Company</div>
              <div class="value">
                <input
                  type="text"
                  v-model="inquiryForm.vendor_company"
                  style="width: 500px !important;"
                />
              </div>
            </div>
          </div>
          <div class="item extra-item">
            <div class="input  input-address">
              <div class="label">Address</div>
              <div class="value value-c">
                <input
                  type="text"
                  v-model="inquiryForm.vendor_address"
                  style="width: 500px !important;"
                />
              </div>
            </div>
          </div>
          <div class="item">
            <div class="input">
              <div class="label">Contact Name</div>
              <div class="value">
                <input type="text" v-model="inquiryForm.vendor_contact" />
              </div>
            </div>
            <div class="input">
              <div class="label">Tel</div>
              <div class="value">
                <input type="text" v-model="inquiryForm.vendor_tel" />
              </div>
            </div>
          </div>
          <div class="item">
            <div class="input">
              <div class="label">Email</div>
              <div class="value">
                <input type="text" v-model="inquiryForm.vendor_email" />
              </div>
            </div>
            <div class="input">
              <div class="label">Website</div>
              <div class="value value-c">
                <input type="text" v-model="inquiryForm.vendor_website" />
              </div>
            </div>
          </div>
          <div class="item">
            <div class="input">
              <div class="label">Note</div>
              <div class="value value-c">
                <textarea
                  type="text"
                  v-model="inquiryForm.vendor_note"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="item" style="justify-content: right">
            <!--            <div class="item-content"> Memo(internal use only)</div>-->
            <div class="item-content need" title="" @click="closeCustomer">
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.7334 8.66211C16.7334 8.55469 16.6455 8.4668 16.5381 8.4668L14.9268 8.47412L12.5 11.3672L10.0757 8.47656L8.46191 8.46924C8.35449 8.46924 8.2666 8.55469 8.2666 8.66455C8.2666 8.71094 8.28369 8.75488 8.31299 8.7915L11.4893 12.5757L8.31299 16.3574C8.28349 16.3932 8.26712 16.438 8.2666 16.4844C8.2666 16.5918 8.35449 16.6797 8.46191 16.6797L10.0757 16.6724L12.5 13.7793L14.9243 16.6699L16.5356 16.6772C16.6431 16.6772 16.731 16.5918 16.731 16.4819C16.731 16.4355 16.7139 16.3916 16.6846 16.355L13.5132 12.5732L16.6895 8.78906C16.7187 8.75488 16.7334 8.7085 16.7334 8.66211Z"
                  fill="#F57F45"
                />
                <path
                  d="M12.5 1.58691C6.45996 1.58691 1.5625 6.48438 1.5625 12.5244C1.5625 18.5645 6.45996 23.4619 12.5 23.4619C18.54 23.4619 23.4375 18.5645 23.4375 12.5244C23.4375 6.48438 18.54 1.58691 12.5 1.58691ZM12.5 21.6064C7.48535 21.6064 3.41797 17.5391 3.41797 12.5244C3.41797 7.50977 7.48535 3.44238 12.5 3.44238C17.5146 3.44238 21.582 7.50977 21.582 12.5244C21.582 17.5391 17.5146 21.6064 12.5 21.6064Z"
                  fill="#F57F45"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="my-info" v-if="showInfo && myQuoteInfo">
        <div class="line-item">
          <div class="item">
            <div class="label">My Note</div>
            <div class="value" :title="myQuoteInfo.note">
              {{ myQuoteInfo.note || "--" }}
            </div>
          </div>
        </div>
        <div class="line-item">
          <div class="item">
            <div class="label">Estimated Annual Quantity</div>
            <div
              class="value"
              :title="myQuoteInfo.quote_estimated_annual_quantity"
            >
              {{ myQuoteInfo.quote_estimated_annual_quantity || "--" }}
            </div>
          </div>
          <div class="item">
            <div class="label">Type</div>
            <div class="value" :title="myQuoteInfo.quote_sourcing_type">
              {{ myQuoteInfo.quote_sourcing_type || "--" }}
            </div>
          </div>
        </div>
        <div class="customer-info" v-if="myQuoteInfo.have_vendor_info">
          <div class="customer-title">Supplier Information</div>
          <div class="line-item">
            <div class="item">
              <div class="label">Company</div>
              <div class="value" :title="myQuoteInfo.vendor_company">
                {{ myQuoteInfo.vendor_company || "--" }}
              </div>
            </div>
            <div class="item">
              <div class="label">Address</div>
              <div class="value" :title="myQuoteInfo.vendor_address">
                {{ myQuoteInfo.vendor_address || "--" }}
              </div>
            </div>
            <div class="item">
              <div class="label">Contact Name</div>
              <div class="value" :title="myQuoteInfo.vendor_contact">
                {{ myQuoteInfo.vendor_contact || "--" }}
              </div>
            </div>
          </div>
          <div class="line-item">
            <div class="item">
              <div class="label">Tel</div>
              <div class="value" :title="myQuoteInfo.vendor_tel">
                {{ myQuoteInfo.vendor_tel || "--" }}
              </div>
            </div>
            <div class="item">
              <div class="label">Email</div>
              <div class="value" :title="myQuoteInfo.vendor_email">
                {{ myQuoteInfo.vendor_email || "--" }}
              </div>
            </div>
            <div class="item">
              <div class="label">Website</div>
              <div class="value" :title="myQuoteInfo.vendor_website">
                {{ myQuoteInfo.vendor_website || "--" }}
              </div>
            </div>
          </div>
          <div class="line-item">
            <div class="item" style="width: 100%;">
              <div class="label">Note</div>
              <div class="value" :title="myQuoteInfo.vendor_note">
                {{ myQuoteInfo.vendor_note || "--" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="info" v-if="showInfo">
        <div class="warning" v-if="this.infoParam.user == ' '">
          The other company has hidden the information, please wait for the
          other company to contact you.
        </div>
        <div class="info-title">Contact Details:</div>

        <div class="info-content">
          <div class="left">
            <div class="item">
              <div class="label">Company :</div>
              <div
                class="value value-company search-button"
                :title="infoParam.company_name"
                v-if="infoParam.website"
              >
                <a
                  :href="infoParam.website"
                  target="_blank"
                  style="color: #f57f45;"
                >
                  {{ infoParam.company_name }}
                </a>
              </div>
              <div
                class="value search-button"
                :title="infoParam.company_name"
                v-else
              >
                {{ infoParam.company_name }}
              </div>
            </div>
            <div class="item" v-if="!showHiddenInfo">
              <div class="label">Contact Name :</div>
              <div class="value value-user" :title="infoParam.user">
                <div class="user">
                  {{ infoParam.user }}
                </div>
              </div>
            </div>
            <div class="item" v-if="!showHiddenInfo">
              <div class="label">Email :</div>
              <div class="value" :title="infoParam.email">
                {{ infoParam.email }}
              </div>
            </div>
            <div class="item" v-if="!showHiddenInfo">
              <div class="label">Phone Number :</div>
              <div class="value" :title="infoParam.phone">
                {{ infoParam.phone }}
              </div>
            </div>
          </div>
          <div class="right" v-if="!showHiddenInfo">
            <div class="avatar">
              <img :src="infoParam.userAvatar" alt="" />
            </div>
          </div>
        </div>
        <div class="more-complete" v-if="!showHiddenInfo">
          <div
            class="more search-button"
            @click="
              viewCompanyQuotation(infoParam.company_id, infoParam.company_name)
            "
          >
            <div class="word">More Products</div>
            <div class="icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.2929 12H4.5C4.22386 12 4 11.7761 4 11.5C4 11.2239 4.22386 11 4.5 11H14.2929L11.1464 7.85355C10.9512 7.65829 10.9512 7.34171 11.1464 7.14645C11.3417 6.95118 11.6583 6.95118 11.8536 7.14645L15.8536 11.1464C16.0488 11.3417 16.0488 11.6583 15.8536 11.8536L11.8536 15.8536C11.6583 16.0488 11.3417 16.0488 11.1464 15.8536C10.9512 15.6583 10.9512 15.3417 11.1464 15.1464L14.2929 12Z"
                  fill="#f57f45"
                />
              </svg>
            </div>
          </div>
          <div class="complete search-button" @click="getImages(infoParam)">
            <div class="word">Complete List</div>
            <div class="icon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.2929 12H4.5C4.22386 12 4 11.7761 4 11.5C4 11.2239 4.22386 11 4.5 11H14.2929L11.1464 7.85355C10.9512 7.65829 10.9512 7.34171 11.1464 7.14645C11.3417 6.95118 11.6583 6.95118 11.8536 7.14645L15.8536 11.1464C16.0488 11.3417 16.0488 11.6583 15.8536 11.8536L11.8536 15.8536C11.6583 16.0488 11.3417 16.0488 11.1464 15.8536C10.9512 15.6583 10.9512 15.3417 11.1464 15.1464L14.2929 12Z"
                  fill="#f57f45"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="right">
          <template v-if="inquiry.status === 1">
            <div
              class="button"
              v-if="inquiry.is_involved !== 1 && !inquiry.is_my_inquiry"
            >
              <!--              <div class="cancel search-button" @click="cancel">Cancel</div>-->
              <div class="save search-button" @click="save" v-if="!btnLoading">
                Submit
              </div>
              <div class="save search-button loading-button" v-else>
                <img src="@/assets/loading.gif" />
              </div>
              <div
                class="add search-button"
                @click="addCollection"
                v-if="inquiry.is_pending_submit === 0"
              >
                Add To Submit List
              </div>
              <div class="already search-button" @click="viewCollection" v-else>
                View In Submit List
              </div>
            </div>
            <div class="button" v-else>
              <!--              <div class="expired-cancel search-button" @click="cancel">-->
              <!--                Cancel-->
              <!--              </div>-->
            </div>
          </template>
          <template v-if="inquiry.status === 2">
            <div class="content"></div>
            <div class="button">
              <!--              <div class="expired-cancel search-button" @click="cancel">-->
              <!--                Cancel-->
              <!--              </div>-->
            </div>
          </template>
          <template v-if="inquiry.status > 2">
            <div class="button">
              <!--              <div class="expired-cancel search-button" @click="cancel">-->
              <!--                Cancel-->
              <!--              </div>-->
            </div>
          </template>
        </div>
      </div>
    </Modal>
    <quotationImages ref="quotationImages"></quotationImages>
    <isGotoCollection ref="isGotoCollection"></isGotoCollection>
  </div>
</template>

<script>
import api from "@/api";
const { _quoteInquiry, _storeTradeCollection, _country } = api;
import { mapState } from "vuex";
import quotationImages from "@/components/quotationImages";
import isGotoCollection from "@/components/isGotoCollection";

export default {
  props: [],
  computed: {
    ...mapState("user", ["role"])
  },
  components: { quotationImages, isGotoCollection },
  data() {
    return {
      inquiryForm: {
        price: "",
        note: "",
        id: "",
        quote_estimated_annual_quantity: "",
        quote_sourcing_type: "",
        vendor_company: null,
        vendor_contact: null,
        vendor_tel: null,
        vendor_email: null,
        vendor_not: null,
        vendor_address: null,
        vendor_website: null
      },
      showInquiry: this.showInquiry,
      loading: false,
      showInfo: false,
      infoParam: {
        company_name: "",
        user: "",
        email: "",
        company_id: 0,
        phone: "",
        website: ""
      },
      btnLoading: false,
      // inquiry: this.inquiry,
      // inquiry: this.inquiry
      inquiry: {},
      showHiddenInfo: false,
      editValueData: {
        quote_quantity: false,
        quote_packaging: false,
        quote_basis: false,
        quote_lead_time: false,
        quote_price: false,
        quote_country: false,
        quote_quality_standard: false,
        quote_shipping_address: false
      },
      editValueObj: {
        quote_quantity: "",
        quote_lead_time: "",
        quote_basis: "",
        quote_price: "",
        quote_packaging: "",
        quote_country: 0,
        quote_quality_standard: "",
        quote_shipping_address: ""
      },
      countryList: [
        { id: 1, name: "United States" },
        { id: 2, name: "Canada" },
        { id: 45, name: "China" }
      ],
      showVendor: false,
      myQuoteInfo: {}
    };
  },
  mounted() {
    _country().then(response => {
      const { data } = response;
      this.countryList = data;
    });
  },
  watch: {
    inquiry(newData) {
      if (newData.is_my_inquiry || newData.is_involved) {
        this.showInfo = true;
        this.showHiddenInfo = false;
        this.infoParam.company_id = newData.company.id;
        this.infoParam.company_name = newData.company.company_name;
        this.infoParam.email = newData.user.email;
        this.infoParam.phone = newData.user.phone;
        this.infoParam.website = newData.company.website;
        this.infoParam.userAvatar = newData.user.avatar.url;
        this.infoParam.user =
          newData.user.first_name + " " + newData.user.last_name;
      } else {
        this.showInfo = false;
        this.infoParam = {
          company_name: " ",
          user: "",
          email: "",
          company_id: 0,
          phone: "",
          website: ""
        };
      }
      this.infoParam.company_id = newData.company.id;
      this.infoParam.company_name = newData.company.company_name;
      this.editValueData = {
        quote_quantity: false,
        quote_packaging: false,
        quote_basis: false,
        quote_lead_time: false,
        quote_price: false,
        quote_country: false,
        quote_quality_standard: false,
        quote_shipping_address: false
      };
      this.editValueObj = {
        quote_quantity: newData.quantity,
        quote_lead_time: newData.lead_time,
        quote_basis: newData.basis,
        quote_price: newData.price,
        quote_packaging: newData.packaging,
        quote_country: newData.country,
        quote_quality_standard: newData.quality_standard,
        quote_shipping_address: newData.shipping_address
      };
      this.myQuoteInfo = newData.my_quote;
    }
  },
  filters: {
    sliceDate(val) {
      if (val) {
        return val.slice(0, 10);
      } else {
        return null;
      }
    }
  },
  methods: {
    closeCustomer() {
      this.showVendor = false;
      this.inquiryForm.vendor_company = this.inquiryForm.vendor_email = this.inquiryForm.vendor_address = this.inquiryForm.vendor_note = this.inquiryForm.vendor_contact = this.inquiryForm.vendor_tel = this.inquiryForm.vendor_website = null;
    },
    showVendorMethod() {
      this.showVendor = true;
    },
    editValue(value) {
      if ("quantity" === value) {
        this.editValueData.quote_quantity = !this.editValueData.quote_quantity;
      }
      if ("packaging" === value) {
        this.editValueData.quote_packaging = !this.editValueData
          .quote_packaging;
      }
      if ("lead_time" === value) {
        this.editValueData.quote_lead_time = !this.editValueData
          .quote_lead_time;
      }
      if ("basis" === value) {
        this.editValueData.quote_basis = !this.editValueData.quote_basis;
      }
      if ("price" === value) {
        this.editValueData.quote_price = !this.editValueData.quote_price;
      }
      if ("country" === value) {
        this.editValueData.quote_country = !this.editValueData.quote_country;
      }
      if ("quality_standard" === value) {
        this.editValueData.quote_quality_standard = !this.editValueData
          .quote_quality_standard;
      }
      if ("shipping_address" === value) {
        this.editValueData.quote_shipping_address = !this.editValueData
          .quote_shipping_address;
      }
    },
    viewCollection() {
      this.$router.push("/trade-collection");
    },
    addCollection() {
      if (this.role === "") {
        this.loading = false;
        this.btnLoading = false;
        this.$Message.error("Please log in first");
        return;
      }
      _storeTradeCollection({
        id: this.inquiry.id
      })
        .then(response => {
          console.log(response);
          // this.$Message.success("success");
          this.$emit("getSourcings");
          this.$refs.isGotoCollection.showGuide = true;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.showInquiry = false;
        });
    },
    getImages(item) {
      this.$refs.quotationImages.showUpload = true;
      this.$refs.quotationImages.companyId = item.company_id;
      this.$refs.quotationImages.companyName = item.company_name;
    },
    viewCompanyQuotation(id, name) {
      const str = Math.random()
        .toString(36)
        .substr(2, 10);
      var data = str + id;
      var hashId = window.btoa(data);
      if ("/trades" === this.$route.path) {
        this.$emit("getAllCompany", [hashId, name]);
      } else {
        this.$router.push(
          "/maxsource?company=" +
            hashId +
            "&search_type=" +
            "2,3,4&hc=n&company_name=" +
            name
        );
      }
    },
    cancel() {
      this.showInquiry = false;
    },
    save() {
      this.loading = true;
      this.btnLoading = true;
      if (this.role === "") {
        this.loading = false;
        this.btnLoading = false;
        this.$Message.error("Please log in first");
        return;
      }
      this.inquiryForm.id = this.inquiry.id;
      this.inquiryForm.type = 2;
      this.inquiryForm = Object.assign(this.inquiryForm, this.editValueObj);
      _quoteInquiry({ ...this.inquiryForm })
        .then(data => {
          this.$Message.success("success");
          this.$emit("getSourcings");
          if (this.inquiry.hidden_company === 0) {
            this.showInfo = true;
            this.infoParam.company_id = data.data.company.id;
            this.infoParam.company_name = data.data.company.company_name;
            this.infoParam.user =
              data.data.user.first_name + " " + data.data.user.last_name;
            this.infoParam.email = data.data.user.email;
            this.infoParam.phone = data.data.user.phone;
            this.infoParam.website = data.data.company.website;
            this.infoParam.userAvatar = data.data.user.avatar.url;
          } else {
            this.showInfo = true;
            this.infoParam.company_name = data.data.company.company_name;
            this.showHiddenInfo = true;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.btnLoading = false;
          this.inquiry.is_involved = 1;
          // this.loading = false;
          // this.showInquiry = false;
          // this.inquiryForm.price = "";
          this.inquiryForm.note = "";
          this.inquiryForm.quote_estimated_annual_quantity = "";
          this.inquiryForm.quote_sourcing_type = "";
          // this.showInfo = true;
        });
    }
  }
};
</script>
<style lang="less" scoped>
.pop-inquiry {
  padding: 30px;
  .top {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    .product {
      font-size: 20px;
      font-weight: 600;
      color: #222;
      max-width: 430px;
      word-break: break-all;
      span {
        color: #939393;
        font-size: 16px;
        font-weight: 600;
        margin-left: 5px;
      }
    }
    .status {
      width: 90px;
      height: 30px;
      border-radius: 5px;
      display: flex;
      justify-content: center !important;
      color: #fff;
      font-size: 13px;
      align-items: center;
    }
    .status-progress {
      background: #11828f;
    }
    .status-expired {
      background: #c0399f;
    }
    .status-completed {
      background: #925acf;
    }
  }
  .content {
    .line-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      .item {
        width: 33%;
        .label {
          color: #8a8a8a;
          font-size: 14px;
          padding-bottom: 3px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: flex;
          .edit-value {
            margin-left: 10px;
            cursor: pointer;
          }
        }
        .value {
          color: #444;
          font-size: 15px;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          input {
            border-radius: 8px;
            height: 32px;
            border: 2px solid #dfdfe6;
            outline: none;
            width: 88%;
            padding-left: 10px;
          }
          select {
            border-radius: 8px;
            height: 32px;
            border: 2px solid #dfdfe6;
            outline: none;
            width: 88%;
            padding-left: 10px;
          }
          input:focus {
            border: 2px solid #f57f45;
          }
        }
      }
      .item-c {
        width: 66%;
      }
      .item-all {
        width: 90%;
      }
    }
  }
  .button {
    display: flex;
    justify-content: right;
    margin-top: 20px;
    .cancel {
      color: #989696;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }
    .add {
      color: #f57f45;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      border: 1px solid #f57f45;
      border-radius: 20px;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
      width: 200px;
      height: 42px;
    }
    .already {
      color: #b5b4b4;
      /*font-size: 18px;*/
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      border: 1px solid #b5b4b4;
      border-radius: 20px;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
      width: 215px;
      height: 42px;
    }

    .expired-cancel {
      background: #bababa;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: #fff;
      padding: 5px 10px 5px 10px;
      border-radius: 5px;
    }
    .save {
      background: #f57f45;
      color: #fff;
      /*width: 82px;*/
      height: 42px;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      width: 200px;
    }
    .loading-button {
      opacity: 0.8;
      img {
        width: 18px;
      }
    }
  }
}
.input-value {
  margin-bottom: 15px;
  .label {
    color: #8a8a8a;
    font-size: 14px;
    padding-bottom: 3px;
  }
  .input {
    input {
      border: 2px solid #dfdfe6;
      outline: none;
      width: 200px;
      height: 40px;
      border-radius: 12px;
      padding-left: 10px;
      margin-top: 10px;
    }
    input:focus {
      border: 2px solid #f57f45;
    }
  }
  .textarea {
    textarea {
      border: 1px solid #c1c1c1;
      outline: none;
      width: 200px;
      border-radius: 5px;
      padding-left: 10px;
    }
  }
}

.company {
  background: #f2f2f2;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 30px;
  .top {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #cccccc;
    margin-top: 5px;
    padding-bottom: 10px;
    .icon {
      position: relative;
      top: 4px;
      right: 5px;
    }
    .name {
      font-size: 18px;
      font-weight: 600;
    }
  }
  .info {
    display: flex;
    justify-content: center;
    .item {
      width: 33%;
      .label {
        font-size: 14px;
        color: #8a8a8a;
        padding-left: 20px;
      }
      .value {
        font-size: 16px;
        color: #000;
        font-weight: 600;
        /*white-space: nowrap;*/
        /*overflow: hidden;*/
        /*text-overflow: ellipsis;*/
        padding-left: 20px;
        word-wrap: break-word;
      }
      .show-value {
        white-space: normal !important;
        word-wrap: break-word;
      }
    }
  }
}
.info {
  background: #b8d9e8;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 15px;
  .info-title {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    margin: 10px 0 10px 0;
  }
  .warning {
    font-size: 14px;
    color: #ff5400;
  }
  .info-content {
    display: flex;
    justify-content: space-between;
    .right .avatar {
      margin: 0 50px 0 0;
      img {
        height: 80px;
        width: 80px;
        border-radius: 10px;
      }
    }
    .item {
      display: flex;
      margin-bottom: 10px;
      .label {
        margin-right: 5px;
        width: 140px;
        font-size: 15px;
        text-align: right;
      }
      .value {
        font-weight: 600;
      }
      .value-user {
        display: flex;
        .avatar {
          margin-right: 10px;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .value-company {
        color: #f57f45;
      }
    }
  }
}
.more-complete {
  display: flex;
  justify-content: right;
  font-size: 11px;
  .more {
    display: flex;
    margin-right: 20px;
    .word {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #f57f45;
      height: 100%;
      font-size: 13px;
    }
    .icon {
      padding-top: 7px;
    }
  }
  .complete {
    display: flex;
    .word {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #f57f45;
      height: 100%;
      font-size: 13px;
    }
    .icon {
      padding-top: 7px;
    }
  }
}
.bottom {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  .left {
    margin-left: 30px;
  }
  .alert {
    display: flex;
    .right {
      width: 350px;
      font-size: 14px;
      height: 56px;

      /* Grays/Casper/CA-700 */

      background: #334155;
      border-radius: 8px;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 1;
      color: #fff;
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 10px;
    }
    .left {
      margin-left: 0;
    }
  }
}
.note {
  margin-bottom: 15px;

  .input .label {
    color: #87898e;
    font-size: 16px;
    margin-bottom: 12px;
  }
  .input .value input {
    border-radius: 12px;
    height: 42px;
    border: 2px solid #dfdfe6;
    outline: none;
    width: 490px;
    padding-left: 10px;
  }
}

.annual {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  .input .label {
    color: #87898e;
    font-size: 16px;
    margin-bottom: 12px;
  }
  .input .value input {
    border-radius: 12px;
    height: 42px;
    border: 2px solid #dfdfe6;
    outline: none;
    /*width: 490px;*/
    padding-left: 10px;
    width: 240px;
  }
  .input .value select {
    border-radius: 12px;
    height: 42px;
    border: 2px solid #dfdfe6;
    outline: none;
    /*width: 490px;*/
    padding-left: 10px;
    width: 240px;
  }
}
.note-a {
  background: #f5f4f4;
  border-radius: 20px;
  padding: 15px;
}
.vendor-notify {
  span {
    position: relative;
    bottom: 4px;
    left: 5px;
    color: #f57f45;
    font-weight: 600;
    font-size: 14px;
  }
}
.vendor {
  /*background: #f1f1f1;*/
  /*border-radius: 20px;*/
  /*padding: 10px;*/
  margin-bottom: 15px;
  margin-top: 15px;
  .item {
    display: flex;
    justify-content: space-between;
    .input {
      margin-bottom: 10px;
    }
  }
  .title {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin: 13px auto;
  }
  .input-address {
    /*width: 555px;*/
  }
  .value {
    input {
      height: 39px;
      width: 250px !important;
      border-radius: 12px;
      border: 2px solid #dfdfe6;
      outline: none;
      padding-left: 10px;
    }
    textarea {
      width: 500px !important;
      border-radius: 12px;
      border: 2px solid #dfdfe6;
      outline: none;
      padding-left: 10px;
    }
  }
  .item-content {
    color: #f57f45;
  }
  .need {
    color: #f57f45;
    cursor: pointer;
    margin-right: 10px;
  }
}
.vendor-extra {
  .value {
    width: 100% !important;
    input {
      width: 100% !important;
    }
  }
}
.vendor-notify-extra {
  margin-top: 10px;
  svg{
    width: 20px;
    height: 20px;
  }
}
.my-info {
  background: #ebebeb;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 15px;
  .line-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    .item {
      width: 50%;
      .value {
        color: #646464;
        font-size: 15px;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .customer-info {
    padding-top: 20px;
    border-top: 1px solid #d3d3d3;
    .customer-title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .value {
      color: #646464;
      font-size: 15px;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.edit-value {
  svg{
    width: 18px;
    height: 18px;
  }
}
.value svg{
  width: 30px;
  height: 30px;
}
</style>

<style lang="less">
.pop-inquiry {
  .ivu-modal {
    width: 620px !important;
    .ivu-modal-content {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
</style>
