<template>
  <div>
    <Modal class="pop-collection" footer-hide v-model="showGuide">
      <!--      <div class="top">-->
      <!--        MaxTrade Instruction-->
      <!--      </div>-->
      <div class="content">
        <div class="success">
          <div class="icon">
            <svg
              width="25"
              height="25"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3359 20.8359L18.3359 25.8359L26.6693 15.8359"
                stroke="#F57F45"
                stroke-width="3.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M20.0026 36.6693C29.2073 36.6693 36.6693 29.2073 36.6693 20.0026C36.6693 10.7979 29.2073 3.33594 20.0026 3.33594C10.7979 3.33594 3.33594 10.7979 3.33594 20.0026C3.33594 29.2073 10.7979 36.6693 20.0026 36.6693Z"
                stroke="#F57F45"
                stroke-width="3.33333"
              />
            </svg>
          </div>
          <div class="word">Add to Pending Submit List successful!</div>
        </div>
        <div class="btns">
          <div class="collection search-button" @click="collection">
            Pending Submit List
          </div>
          <div class="close-btn search-button" @click="cancel">Close</div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["inquiry"],
  computed: {
    ...mapState("user", ["role"])
  },
  data() {
    return {
      inquiryForm: { price: "", note: "", id: "" },
      showGuide: false,
      loading: false,
      formData: new FormData(),
      current: "buy"
      // inquriy: {}
    };
  },
  mounted() {},
  filters: {},
  methods: {
    changeCurrent(current) {
      this.current = current;
    },
    getFile(event, input_file_name) {
      this.formData.append(input_file_name, event.target.files[0]);
    },
    collection() {
      this.$router.push("/trade-collection");
    },
    cancel() {
      this.showGuide = false;
    }
  }
};
</script>
<style lang="less" scoped>
.pop-collection {
  .top {
    color: #23262f;
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .template {
    padding: 20px 0;
    span {
      font-weight: 600;
      a {
        color: #f57f45;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: right;
    .cancel {
      color: #989696;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }
    .upload {
      background: #f57f45;
      color: #fff;
      height: 35px;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .uploading {
      background: #ffb894;
    }
    .loading-button {
      opacity: 0.8;
      img {
        width: 18px;
      }
    }
  }
}
.content {
  .tabs {
    display: flex;
    justify-content: space-between;
    .tab {
      width: 50%;
      text-align: center;
      .tab-word {
        font-size: 17px;
        font-weight: 600;
      }
      .active-tab-word {
        font-size: 18px;
        font-weight: 600;
        color: #f57f45;
      }
      .tab-line {
        height: 2px;
        background: #f57f45;
        position: relative;
        top: 16px;
      }
    }
  }
  .tabs-line {
    width: 100%;
    height: 1px;
    background: #d9d9d9;
    margin: 15px auto 15px auto;
  }
}
.steps-content {
  .step {
    /*width: 860px;*/
    margin: 0 auto;
    display: flex;
    .left {
      .step-line {
        width: 1px;
        height: 10px;
        background: rgba(245, 124, 0, 0.3);
        margin: 10px auto;
      }
      .item-number {
        width: 25px;
        height: 25px;
        background: rgba(245, 124, 0, 0.3);
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        margin-right: 10px;
        color: #f57f45;
      }
    }
    .right {
      .step-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #4f4f4f;
        display: flex;
        justify-content: left;
        /*margin-bottom: 10px;*/
      }
      .step-body {
        /*font-weight: 600;*/
        font-size: 15px;
        text-align: left;
        color: #4f4f4f;
        margin-top: 2px;
      }
    }
  }
}
.close {
  margin: 20px auto 0 auto;
  width: 150px;
  background: #f57f45;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  border-radius: 10px;
  color: #fff;
  height: 35px;
}
.content {
  .success {
    display: flex;
    justify-content: center;
    .icon {
      position: relative;
      top: 14px;
      right: 5px;
    }
    .word {
      text-align: center;
      font-size: 18px;
      margin-bottom: 20px;
      margin-top: 15px;
      font-weight: 600;
    }
  }
  .btns {
    display: flex;
    justify-content: center;
    .collection {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      background: #f57f45;
      width: 220px;
      color: #fff;
      height: 35px;
      border-radius: 10px;
      margin-right: 20px;
    }
    .close-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      height: 35px;
      border-radius: 10px;
      border: 1px solid #a3a3a3;
      color: #a3a3a3;
    }
  }
}
</style>

<style lang="less">
.pop-guide {
  .ivu-modal {
    width: 460px !important;
    .ivu-modal-content {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
</style>
